import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import storage from '../../utils/storage';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const pulse = keyframes`
0% {transform: scale(1);}
50% {transform: scale(1.3);}
100% {transform: scale(1);}
`;

const TyContainer = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800');
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  width: 100%;
  min-height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  background: #000;
  color: #fff;
`;

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  text-align: center;

  p {
    font-size: 20px;
  }

  h1 {
    margin-bottom: 10px;
  }
`;

const LogoImgContainer = styled.div`
  width: 100%;
  max-width: 45px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
`;

const MiddleContainer = styled.div`
  background: url('https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/solarPanelsWithSky.png')
    50% 0/100% 45vh no-repeat;
  display: flex;
  width: 100%;
  min-height: 45vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  h1 {
    margin-bottom: 0;
  }
  p {
    font-size: 36px;
    @media (max-width: 801px) {
      font-size: 22px;
      text-align: center;
      margin-top: 8px;
    }
  }

  button {
    border: 1px solid #000;
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    background: #e07523;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    padding: 20px 0;
    animation: ${pulse} 2s infinite;
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
`;

const LowerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
  div {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }
    p,
    ul {
      font-size: 19px;
    }

    li {
      list-style-type: none;
      position: relative; /* It's needed for setting position to absolute in the next rule. */
    }

    li::before {
      content: '';
      position: absolute;
      top: 4px;
      left: -30px;
      height: 20px;
      width: 20px;
      border-radius: 30px;
      background: #e07523;
    }
  }

  @media (max-width: 801px) {
    flex-direction: column;
    div {
      width: 100%;
      p {
        display: none;
      }
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;

  p {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
  }
`;

const EhsceeThankyou = () => {
  const [compressionKey, setCompressionKey] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      const formData = storage('session', 'get', 'jtkNetFd');

      if (formData) {
        const parsedData = JSON.parse(formData);
        const pageCode = {
          PageCode: 'ehscee',
        };

        const compressionData = { ...parsedData, ...pageCode };
        const compressedValue = await window?.jtknet?.networkDataCompression(
          compressionData
        );
        setData(parsedData);
        setCompressionKey(compressedValue);
      }
    })();
  }, []);

  const handleClick = () => {
    const Address = data?.Address?.replaceAll(' ', '+');

    const prePoppedLink = `https://trkthat4.com/?oex3=4WeOtr8yfvDIhEz3KN6ueTG7Dj%2frHPqx&s1=1764|${compressionKey ||
      ''}&s2=${compressionKey || ''}&s3=${data?.UUID ||
      ''}&zip=${data?.ZipCode || ''}&first_name=${data?.FirstName ||
      ''}&last_name=${data?.LastName ||
      ''}&street=${Address}&SRCID=${data?.CkmAffiliateId ||
      ''}&city=${data?.City || ''}&state=${data?.State ||
      ''}&phone_home=${data?.PhoneNumber || ''}`;

    navigate(prePoppedLink);
  };

  return (
    <TyContainer>
      <Header>
        <ImageContainer>
          <LogoImgContainer>
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/freeSolarQuotes.png"
              alt="Solar panels"
            />
          </LogoImgContainer>
          <p>Free Solar Quotes</p>
        </ImageContainer>
      </Header>
      <MiddleContainer>
        <h1>Great News!</h1>
        <p>You have matched with our featured provider</p>
        <button type="button" onClick={handleClick}>
          CONTINUE
        </button>
      </MiddleContainer>
      <LowerContainer>
        <div>
          <h2>What happens next?</h2>
          <p>
            We match you with a solar provider in your area in minutes. Rooftop
            solar panels -- back up battery options and more.
          </p>
        </div>
        <div>
          <ul>
            <li>Save on Energy Bills</li>
            <li>Boost Your Home Value</li>
            <li>Get Future Ready</li>
          </ul>
        </div>
      </LowerContainer>
    </TyContainer>
  );
};

export default withThankyouWrapper(EhsceeThankyou);
